<template>
  <div class="login">
    <!-- <HelloWorld msg="Welcome to Your Vue.js App" /> -->
    <!-- <div class="login-box">
      <div class="title">欢 迎 登 录 在 线 备 课 系 统</div>
      <input
        type="text"
        id="user"
        placeholder="请输入手机号/用户名"
        autocomplete="off"
      />
      <input
        type="password"
        id="pwd"
        placeholder="登录密码"
        autocomplete="off"
      />
      <div class="choose">
        <el-checkbox v-model="checked" class="left">记住密码</el-checkbox>
        <div class="right"><span>忘记密码</span> | <span>注册</span></div>
      </div>
      <div class="login-btn" @click="toMain">
        <img src="../img/登录_slices/矩形 2 拷贝 2.png" alt="" />
        <span>登录</span>
      </div>
      <div class="line">
        <div class="line1"></div>
        <div class="text">第三方登录</div>
        <div class="line1"></div>
      </div>
      <div class="login-wx">
        <img src="../img/登录_slices/微信.png" alt="" />
      </div>
    </div> -->
    <router-view></router-view>
  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from "@/components/HelloWorld.vue";

export default {
  name: "Login",
  data() {
    return {
      checked: false,
    };
  },
  mounted() {
    // console.log(this.$route.path);
    if (this.$route.path == "/") {
      this.$router.push("/login/logins");
    }
  },
  methods: {},
  components: {
    // HelloWorld,
  },
};
</script>
<style lang="less" scoped>
.login {
  height: 100vh;
  background: #fff url("../img/登录_slices/图层1@2x.png") no-repeat;
  background-size: cover;
}
</style>
